<template>
  <div class="container-expand statistics-filters-container">
    <h4 class="hint-title">{{ $lang.app.statistics_instruction }}</h4>
    <div class="filters-tabs">
      <div class="app-tabs">
        <button v-for="organizationType in translatedOrganizationTypes" :key="organizationType.value"
                @click.prevent="organizationForm.type = organizationType.value"
                class="app-tab"
                :class="{'app-tab-active': organizationType.value === organizationForm.type }">
          {{ organizationType.label }}
        </button>
      </div>
    </div>
    <div class="filters-form">
      <div class="double-input">
        <div class="reg-step-form-item">
          <label class="recovery-label">{{ $lang.app.region_city }}</label>
          <div class="reg-step-1-select-container">
            <i class="bi-chevron-down"></i>
            <Field as="select" name="regionId"
                   v-model="organizationForm.regionId">
              <option v-for="region in helper.regions" :key="region.id"
                      :value="region.id">{{ region.label }}
              </option>
            </Field>
          </div>
          <ErrorMessage name="regionId" class="recovery-label-error"/>
        </div>
        <div v-if="helper.districts.length" class="reg-step-form-item">
          <label class="recovery-label">{{ $lang.app.raion }}</label>
          <div class="reg-step-1-select-container">
            <i class="bi-chevron-down"></i>
            <Field as="select" name="districtId"
                   v-model="organizationForm.districtId">
              <option v-for="district in helper.districts" :key="district.id"
                      :value="district.id">{{ district.label }}
              </option>
            </Field>
          </div>
          <ErrorMessage name="districtId" class="recovery-label-error"/>
        </div>
      </div>
      <div class="double-input">
<!--        <div v-if="helper.aymaks.length" class="reg-step-form-item">-->
<!--          <label class="recovery-label">{{ $lang.app.aimak }}</label>-->
<!--          <div class="reg-step-1-select-container">-->
<!--            <i class="bi-chevron-down"></i>-->
<!--            <Field as="select" name="aymakId"-->
<!--                   v-model="organizationForm.aymakId">-->
<!--              <option v-for="aymak in helper.aymaks" :key="aymak.id"-->
<!--                      :value="aymak.id">{{ aymak.label }}-->
<!--              </option>-->
<!--            </Field>-->
<!--          </div>-->
<!--          <ErrorMessage name="aymakId" class="recovery-label-error"/>-->
<!--        </div>-->
        <div class="reg-step-form-item">
          <label class="recovery-label">{{ $lang.app.organization }}</label>
          <div class="reg-step-1-select-container select-control-wrapper">
            <i class="bi-chevron-down"></i>
            <Multiselect
                v-model="organizationForm.organizationId"
                :options="helper.organizations"
                valueProp="id"
                :searchable="true"
                :noOptionsText="$lang.app.list_empty"
                :noResultsText="$lang.app.not_found"
            />
          </div>
          <ErrorMessage name="organizationId" class="recovery-label-error"/>
        </div>
      </div>
    </div>
    <PreschoolResult v-if="result && this.organizationForm.type === 'PRESCHOOL'"
                     :result="result"/>
    <PreschoolResultByAddress
        v-if="isPreschoolStatisticsEmpty"
        :preschoolStatisticsByAddressDto="preschoolResultByAddress.organizationStatisticsByAddresses"
        :region="organizationForm.regionId"
        :district="organizationForm.districtId"/>
    <PreschoolResultByAddress
        v-if="isPreschoolStatisticsByOshCityEmpty"
        :preschoolStatisticsByAddressDto="preschoolResultByAddress.oshCityOrganizationStatistics"
        :region="7412"
        :district="7412"/>

    <SchoolResult v-if="result && this.organizationForm.type === 'SCHOOL'"
                  :result="result"/>
    <SchoolResultByAddress
        v-if="isSchoolStatisticsEmpty"
        :schoolStatisticsByAddressDto="schoolResultByAddress.organizationStatisticsByAddresses"
        :region="organizationForm.regionId"
        :district="organizationForm.districtId"/>
    <SchoolResultByAddress
        v-if="isSchoolStatisticsByOshCityEmpty"
        :schoolStatisticsByAddressDto="schoolResultByAddress.oshCityOrganizationStatistics"
        :region="7412"
        :district="7412"/>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import "@vueform/multiselect/themes/default.css"
import {Field, ErrorMessage} from 'vee-validate'
import PreschoolResult from '../../components/statistics/PreschoolResult'
import SchoolResult from '../../components/statistics/SchoolResult'
import PreschoolResultByAddress from "@/components/statistics/PreschoolResultByAddress.vue";
import SchoolResultByAddress from "@/components/statistics/SchoolResultByAddress.vue";

export default {
  name: 'StatisticsFilters',
  components: {
    SchoolResultByAddress,
    PreschoolResultByAddress,
    Multiselect,
    Field,
    ErrorMessage,
    PreschoolResult,
    SchoolResult,
  },
  data() {
    return {
      organizationForm: {
        type: 'SCHOOL',
        regionId: null,
        districtId: null,
        aymakId: null,
        organizationId: null,
      },
      helper: {
        organizationTypes: [
          {
            label: { ru: 'Школа', kg: 'Мектеп' },
            value: 'SCHOOL'
          },
          {
            label: { ru: 'Детский сад', kg: 'Бала бакча' },
            value: 'PRESCHOOL'
          }
        ],
        regions: [],
        districts: [],
        aymaks: [],
        organizations: [],
      },
      result: null,
      preschoolResultByAddress: {
        organizationStatisticsByAddresses: [],
        oshCityOrganizationStatistics: [],
      },
      schoolResultByAddress: {
        organizationStatisticsByAddresses: [],
        oshCityOrganizationStatistics: [],
      },
    }
  },
  created() {
    this.fetchChild(4948);
    this.setOrganizationsStatisticsByAddress()
  },
  computed: {
    translatedOrganizationTypes() {
      return this.helper.organizationTypes.map(type => {
        return {
          ...type,
          label: this.$lang.$translate(type.label)
        };
      });
    },
    isPreschoolStatisticsEmpty() {
      let statistics = this.preschoolResultByAddress.organizationStatisticsByAddresses;
      return this.organizationForm.type === 'PRESCHOOL' && Array.isArray(statistics) && statistics.length
    },
    isPreschoolStatisticsByOshCityEmpty() {
      let statistics = this.preschoolResultByAddress.oshCityOrganizationStatistics;
      return this.organizationForm.type === 'PRESCHOOL' && Array.isArray(statistics) && statistics.length
    },
    isSchoolStatisticsEmpty() {
      let statistics = this.schoolResultByAddress.organizationStatisticsByAddresses;
      return this.organizationForm.type === 'SCHOOL' && Array.isArray(statistics) && statistics.length
    },
    isSchoolStatisticsByOshCityEmpty() {
      let statistics = this.schoolResultByAddress.oshCityOrganizationStatistics;
      return this.organizationForm.type === 'SCHOOL' && Array.isArray(statistics) && statistics.length
    },
  },
  watch: {
    '$lang.currentLanguage'() {
      this.setOrganizationTypesTranslations();
    },
    'organizationForm.type'() {
      this.organizationForm.regionId = null;
      this.organizationForm.districtId = null;
      this.organizationForm.aymakId = null;
      this.organizationForm.organizationId = null;
      this.helper.districts = [];
      this.helper.aymaks = [];
      this.helper.organizations = [];
      this.result = null;
      this.preschoolResultByAddress.organizationStatisticsByAddresses = [];
      this.preschoolResultByAddress.oshCityOrganizationStatistics = [];
      this.schoolResultByAddress.organizationStatisticsByAddresses = [];
      this.schoolResultByAddress.oshCityOrganizationStatistics = [];
      this.setOrganizationsStatisticsByAddress()
    },
    'helper.organizations'() {
      this.organizationForm.organizationId = null
    },
    'organizationForm.regionId'(value) {
      this.organizationForm.districtId = null;
      this.organizationForm.aymakId = null;
      this.helper.districts = [];
      this.helper.aymaks = [];
      if (value) {
        this.fetchChild(value)
        this.setOrganizations()
        this.setOrganizationsStatisticsByAddress()
      }
    },
    'organizationForm.districtId'(value) {
      this.organizationForm.aymakId = null;
      this.helper.aymaks = [];
      if (value) {
        this.fetchChild(value)
        this.setOrganizations()
        this.setOrganizationsStatisticsByAddress()
      }
    },
    'organizationForm.aymakId'(value) {
      if (value) {
        this.setOrganizations()
      }
    },
    'organizationForm.organizationId'(value) {
      if (value) {
        this.preschoolResultByAddress.organizationStatisticsByAddresses = [];
        this.preschoolResultByAddress.oshCityOrganizationStatistics = [];
        this.schoolResultByAddress.organizationStatisticsByAddresses = [];
        this.schoolResultByAddress.oshCityOrganizationStatistics = [];
        this.getOrganizationQuotaQuantities(value)
      }
    },
  },
  methods: {
    setOrganizationTypesTranslations() {
      const schoolLabel = this.$lang.$translate({ ru: 'Школа', kg: 'Мектеп' });
      const preschoolLabel = this.$lang.$translate({ ru: 'Детский сад', kg: 'Бала хонасы' });

      this.helper.organizationTypes.forEach(type => {
        if (type.value === 'SCHOOL') {
          type.label = schoolLabel;
        } else if (type.value === 'PRESCHOOL') {
          type.label = preschoolLabel;
        }
      });
    },
    fetchChild(id) {
      return this.$axios.get(`/address-service/children?parent=${id}`)
          .then(({data}) => {
            if (data.length > 0) {
              switch (data[0].type) {
                case 5:
                case 2:
                  this.helper.regions = this.getParsed(data).sort((a, b) => a.label.localeCompare(b.label));
                  break;
                case 3:
                case 6:
                  this.helper.districts = this.getParsed(data).sort((a, b) => a.label.localeCompare(b.label));
                  break;
                case 4:
                case 7:
                case 101:
                  this.helper.aymaks = this.getParsed(data)
                  break;
              }
            }
          })
    },
    getParsed(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({kg: item.nameKg + ' ' + item.typeKgShort, ru: item.nameRu})
        }
      })
    },
    setOrganizations() {
      if (this.organizationForm.regionId) {
        this.$axios.get('/organization/filter', {
          params: {
            regionId: this.organizationForm.regionId,
            districtId: this.organizationForm.districtId,
            aimakId: this.organizationForm.aymakId,
            type: this.organizationForm.type,
          }
        }).then(({data}) => {
          this.helper.organizations = this.getParsedOrganizations(data)
        })
      }
    },
    getParsedOrganizations(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({kg: item.nameKG, ru: item.nameRU})
        }
      })
    },
    async setOrganizationsStatisticsByAddress() {
      this.result = null;
      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')

      this.$axios.post('/statistics/organization/address/filter', {
        regionId: this.organizationForm.regionId,
        districtId: this.organizationForm.districtId,
        type: this.organizationForm.type,
        recaptchaToken: recaptchaToken,
      }).then(({data}) => {
        if (this.organizationForm.type === 'PRESCHOOL') {
          this.preschoolResultByAddress.organizationStatisticsByAddresses = data.organizationStatisticsByAddresses;
          this.preschoolResultByAddress.oshCityOrganizationStatistics = data.organizationStatisticsByCityOsh;
          this.schoolResultByAddress.organizationStatisticsByAddresses = [];
          this.schoolResultByAddress.oshCityOrganizationStatistics = [];
        } else if (this.organizationForm.type === 'SCHOOL') {
          this.schoolResultByAddress.organizationStatisticsByAddresses = data.organizationStatisticsByAddresses;
          this.schoolResultByAddress.oshCityOrganizationStatistics = data.organizationStatisticsByCityOsh;
          this.preschoolResultByAddress.organizationStatisticsByAddresses = [];
          this.preschoolResultByAddress.oshCityOrganizationStatistics = [];
        }
      })
    },
    async getOrganizationQuotaQuantities(organizationId) {

      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/statistics/organization/${organizationId}`, {
        type: this.organizationForm.type,
        recaptchaToken: recaptchaToken,
      })
          .then(({data}) => {
            this.result = data
          })
    }
  }
}
</script>
<style>
@import '../../assets/styles/form.css';
@import '../../assets/styles/tabs.css';

.select-control-wrapper .multiselect {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  margin: 0;
}

.select-control-wrapper .multiselect .multiselect-caret {
  display: none;
}

.select-control-wrapper .multiselect .multiselect-caret {
  display: none;
}

.statistics-filters-container {
  width: 60%;
  margin: 0 auto;
  padding: 55px 0 45px;
}

@media screen and (max-width: 1200px) {
  .statistics-filters-container {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .statistics-filters-container {
    width: calc(100% - 30px);
    padding: 30px 0 35px;
  }

  .filters-form {
    width: 100%;
  }
}
</style>