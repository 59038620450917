<template>
  <div>
    <div class="organization-guotas-table">
      <table>
        <thead>
        <tr>
          <th v-if="!region && !district"><h5>{{ $lang.app.statistic.region_city }}</h5></th>
          <th v-if="region && !district"><h5>{{ $lang.app.statistic.district_city }}</h5></th>
          <th v-if="(!region) || (!district)"><h5>{{ $lang.app.statistic.amount_school }}</h5></th>
          <th v-if="district"><h5>{{ $lang.app.statistic.school_name }}</h5></th>
          <th><h5>{{ $lang.app.statistic.number_of_quota }}</h5></th>
          <th><h5>{{ $lang.app.statistic.number_of_enrolled_child_in_the_school }}</h5></th>
          <th><h5>{{ $lang.app.statistic.number_of_free_places }}</h5></th>
          <th><h5>{{ $lang.app.statistic.number_of_waiting_app_in_the_school }}</h5></th>
          <th><h5>{{ $lang.app.statistic.number_of_waiting_privileges_app_in_the_school }}</h5></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(statistic, index) in schoolStatisticsByAddressDto" :key="index">
          <td v-if="statistic.region">
            <div>
              <span>{{ statistic.region }}</span>
            </div>
          </td>
          <td v-if="statistic.district">
            <div>
              <span>{{ statistic.district }}</span>
            </div>
          </td>
          <td v-if="statistic.organizationAmount">
            <div>
              <span>{{ statistic.organizationAmount }}</span>
            </div>
          </td>
          <td v-if="statistic.organizationName">
            <div>
              <span>{{ statistic.organizationName }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ statistic.quota }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ statistic.enrolled }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ statistic.free }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ statistic.waiting }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ statistic.privilegedWaiting }}</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    schoolStatisticsByAddressDto: Array,
    region: Number,
    district: Number,

  }
}

</script>
<style>
.organization-guotas-table {
  padding: 10px 0 40px;
}
.organization-guotas-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #93cac4;
}
.organization-guotas-table table th, .organization-guotas-table table td {
  padding: 0 5px;
}
.organization-guotas-table thead tr {
  background: #cfe6e3;
}
.organization-guotas-table thead tr .title-number {
  width: 50px;
  background: #93cac4;
}
.organization-guotas-table thead tr h5 {
  margin: 10px 0;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
.organization-guotas-table tbody td, .organization-guotas-table thead th {
  text-align: center;
  border: 1px solid #93cac4;
}
.organization-guotas-table tbody .body-number {
  background: #cfe6e3;
}
.organization-guotas-table tbody .body-number {
  color: #1f1f1f;
}
.organization-guotas-table tbody td div {
  color: #1f1f1f;
  font-size: 16px;
  margin: 7px 0;
  display: inline-block;
}
@media screen and (max-width: 1200px) {
  .organization-guotas-table {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 479px) {
  .organization-guotas-table thead tr h5 {
    font-size: 14px;
  }
  .organization-guotas-table tbody td div {
    font-size: 14px;
  }
}
</style>