<template>
  <div>
    <div class="statistics-filters-results">
      <div class="result-item-wrapper-school">
        <div class="result-item status-pending">
          <div class="result-heading">
            <h5>{{ $lang.app.quotas }}</h5>
            <i class="bi-people"></i>
          </div>
          <div class="result-content">
            <p>{{ $lang.app.quantity }}: <span>{{ this.result.totalQuota }}</span></p>
            <p>{{ $lang.app.ordinary }}: <span>{{ this.result.ordinaryQuota }}</span></p>
            <p>{{ $lang.app.privileged }}: <span>{{ this.result.privilegeQuota }}</span></p>
          </div>
        </div>
      </div>
      <div class="result-item-wrapper-school">
        <div class="result-item status-enrolled">
          <div class="result-heading">
            <h5>{{ $lang.app.enrolled }}</h5>
            <i class="bi-check-all"></i>
          </div>
          <div class="result-content">
            <p>{{ $lang.app.quantity }}: <span>{{ this.result.totalEnrolled }}</span></p>
            <p>{{ $lang.app.ordinary }}: <span>{{ this.result.ordinaryEnrolled }}</span></p>
            <p>{{ $lang.app.privileged }}: <span>{{ this.result.privilegeEnrolled }}</span></p>
          </div>
        </div>
      </div>
      <div class="result-item-wrapper-school">
        <div class="result-item beneficiaries">
          <div class="result-heading">
            <h5 v-html="$lang.app.free_quotas"></h5>
            <i class="bi-bookmark"></i>
          </div>
          <div class="result-content">
            <p>{{ $lang.app.quantity }}: <span>{{ this.result.totalFree }}</span></p>
            <p>{{ $lang.app.ordinary }}: <span>{{ this.result.ordinaryFree }}</span></p>
            <p>{{ $lang.app.privileged }}: <span>{{ this.result.privilegeFree }}</span></p>
          </div>
        </div>
      </div>
      <div class="result-item-wrapper-school">
        <div class="result-item waiting">
          <div class="result-heading">
            <h5>{{ $lang.app.waiting_for_enroll_school }}</h5>
            <i class="bi-hourglass"></i>
          </div>
          <div class="result-content">
            <p>{{ $lang.app.quantity }}: <span>{{ this.result.totalWaiting }}</span></p>
            <p>{{ $lang.app.as_legal_representative }}: <span>{{ this.result.ordinaryWaiting }}</span></p>
            <p>{{ $lang.app.privileged }}: <span>{{ this.result.privilegeWaiting }}</span></p>

          </div>
        </div>
      </div>
    </div>
    <SchoolGroups :groups="result.groupQuotaDtos"/>
  </div>
</template>
<script>
import SchoolGroups from "./SchoolGroups";

export default {
  props: ['result'],
  components: {
    SchoolGroups,
  }
}
</script>
<style>
.statistics-filters-results {
  margin: 40px -8px 10px;
  display: flex;
  flex-wrap: wrap;
}
.statistics-filters-results .result-item-wrapper-school {
  width: 25%;
  padding: 0 8px;
  margin-bottom: 16px;
}
.statistics-filters-results .result-item {
  padding: 16px 10px;
  background: lightgray;
  text-align: center;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.result-heading {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.statistics-filters-results .result-item h5 {
  margin-bottom: 0;
}
.statistics-filters-results .result-item i {
  font-size: 40px;
}
.statistics-filters-results .result-item p {
  margin-bottom: 0;
}
.statistics-filters-results .result-item p span {
  font-size: 24px;
}
.statistics-filters-results .result-item.status-enrolled {
  background: mediumseagreen;
}
.statistics-filters-results .result-item.status-pending {
  background: orange;
}
.statistics-filters-results .result-item.status-declined {
  background: indianred;
}
.statistics-filters-results .result-item.beneficiaries {
  background: skyblue;
}
.statistics-filters-results .result-item.waiting-enrolling {
  background: orchid;
}
.statistics-filters-results .result-item.waiting {
  background: mediumpurple;
}
@media screen and (max-width: 767px) {
  .statistics-filters-results .result-item-wrapper-school {
    width: 50%;
  }
  .statistics-filters-results {
    margin: 40px -8px 10px;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>